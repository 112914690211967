/*@import url("https://fonts.googleapis.com/css?family=Merriweather|Open+Sans+Condensed:300|PT+Sans+Narrow|Roboto|Roboto+Condensed");*/
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src:url('../fonts/poppins/Poppins-Regular.ttf') format('truetype'),
    url('../fonts/poppins/Poppins-SemiBold.ttf') format('truetype')
}


* {
    font-family: 'Poppins', sans-serif !important;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased; 
    -moz-osx-font-smoothing: grayscale;
}


body{ 
    display:flex; 
    flex-direction:column; 
    /* min-height: 100vh; */
  }
  
  footer{
    margin-top:auto; 
    background: #107c8e;
    color:#fff;
    min-width: 390px;
    width: 100%;
    margin-bottom: 0;
  }


th{
    font-size: 14px;
    font-weight: 400;
}
h6{
    font-size: 15px;
}

td{
    font-size: 14px;
}

html{
    box-sizing: border-box;
}
*, *:before, *:after{
    box-sizing: inherit;
}

ul {
    text-decoration: none;
}
table thead th, table tbody td{
    font-size: 14px;
}
.table-bordered.custom-bordered-table thead th, .table-bordered.custom-bordered-table tbody td {
    border-bottom-width: 0;
}

.general-ledger-header {
    padding: 10px 20px;
    border-bottom: 1px solid #eee;
    /*background-color: #fafafa;*/
}
.accounting-period{
    max-width: 900px;
    width: 90%;
    margin: 5rem auto 10rem;
}
.mui-tables {
    margin: 0 10px;
}

.form__label, .form__control, .form_check {
    font-size: 14px;
}
.form__control {
    height: calc(1.4em + .75rem + 2px);
    border-radius: .7rem;
}
.add-service-card {
    max-width: 800px;
    width: 90%;
    margin: 0 auto;
}
.profile-table.table-bordered td, .profile-table.table-bordered th {
    border: 1px solid #606060;
}
.journals .content-header {
    margin-bottom: 0;
}
.new-entry-card {
    max-width: 1100px;
    width: 90%;
    margin: 0 auto;
}
.MuiCard-root.general-card {
    overflow: initial !important;
}
.help-block {
    color: rgb(199, 40, 40);
}
.pharmacy-grey-btn {
    background-color: #eee;
    color: #4a4a4a;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: 1px solid #DCDCDC;
}

.pharmacy-grey-btn:hover {
    border: 1px solid #eee;
    background-color: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    color: #1d1d1d;
}
table.general-ledger-table tbody tr:nth-child(even) td {
    border-bottom: 1px solid #e4e4e4;
}
table.trial-table tfoot td:last-child, table.trial-table tfoot td:nth-child(2), table.balance-sheet-data > tfoot td:last-child,
table.invoice-table > tfoot td:last-child{
    border-top: 1px solid #606060;
    border-bottom: 4px double #606060;
}

table.balance-sheet-data > tfoot td {
    border-top: 1px solid #606060;
}
.top-bar {
    border-bottom: 1px solid #e4e4e4;
    padding: 18px 10px 18px;
}

.top-bar.all-journals {
    background-color: #f8f7f7;
}
.sub-journal-bar{
    padding: 10px;
    border-bottom: 2px dashed #c0dff7;
    background-color: #f7faff;
}
.sub-journal-bar.approve-purchase-order{
    border-bottom: 1px solid #DCDCDC;
}
.debit-table{
    margin: 0;
}
.border-tr{
    border-bottom: 1px solid #424242;
}
table.vendor-address-table p,table.vendor-address-table h4,table.vendor-address-table h6{
    margin: 0;
}

table.vendor-address-table tbody td{
    padding: 0;
}
.visit-heading {
    border-bottom: 1px solid #DCDCDC;
    margin-bottom: 30px;
    font-size:15px
}
.form-control{
    font-size: 14px;
}

.form__control {
    height: calc(1.4em + 0.75rem + 2px);
    border-radius: 0.7rem;
}
.btn-icon{
    font-size: 14px;
}
.mui-menu-item{
    font-size:14px !important;
    color:#000000DE ;
}
#printableArea{
    font-size:0.9rem  !important;
}
.disabled-item{
    pointer-events: none;
    cursor: not-allowed;
}
/* footer{
    background: #107c8e;
    color:#fff;
    width: 100vw;
    /*margin-top:calc(5% + 60px);*/
    /* bottom: 0; */


/* } */ */
.btn{
    font-size: 14px;
}
.row.mpeke-footer{
    margin-right: 0;
}


/*.row{*/
/*    margin: 0 !important;*/
/*}*/
.mpeke-wrapper{
    min-height: 100vh;
}

.content-wrapper {
    /*position: relative;*/
    min-height: 500px;
    overflow: hidden;
    webkit-transition: -webkit-transform .3s ease-in-out, margin .3s ease-in-out;
    -webkit-transition: margin .3s ease-in-out, -webkit-transform .3s ease-in-out;
    background-color: #f1f3f6;
    margin-left: 250px;
    z-index: 800;

}
.wrapper-responsive {
    /*position: relative;*/
    min-height: 500px;
    overflow: hidden;
    webkit-transition: -webkit-transform .3s ease-in-out, margin .3s ease-in-out;
    -webkit-transition: margin .3s ease-in-out, -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out, margin .3s ease-in-out, -webkit-transform .3s ease-in-out;
    background-color: #f1f3f6;
    margin-left: 0;
    z-index: 800;

}

.main-header {
    top: 0;
    right: 0;
    left: 0;
}

.main-header {
    /*max-height: 120px;*/
    /*z-index: 1030;*/
    /*margin-left: 250px;*/
}
.responsive-header{
    /*max-height: 120px;*/
    top: 0;
    right: 0;
    left: 0;

}

.main-header, .main-sidebar, .responsive-header{
    /*position: fixed;*/
}

.main-header .navbar {
    transition:  .3s ease-in-out;
    margin-bottom: 0;
    margin-left: 250px;
    border: none;
    /*min-height: 60px;*/
    border-radius: 0;
    background-color: #fff;
    border-bottom: 1px solid #e1e6ef;
}
.responsive-header .navbar {
    transition:  .3s ease-in-out;
    margin-bottom: 0;
    margin-left: 0px;
    /*border: none;*/
    /*min-height: 60px;*/
    border-radius: 0;
    background-color: #fff;
    border-bottom: 1px solid #e1e6ef;
}

.main-header .sidebar-toggle {
    background-color: transparent;
    padding: 16px 14px;
    font-family: fontAwesome;
    border-right: 1px solid #e1e6ef;
    color: #374767;
    font-size: 26px;
    line-height: 26px;
}
.mpeke-customer-table td, .mpeke-customer-table th {
    border: 1px solid #717579 !important;
}
.mpeke-heading {
    border-bottom: 1px solid #DCDCDC;
    //margin-bottom: 30px;
    font-size: 15px
}
.responsive-header .sidebar-toggle {
    background-color: transparent;
    padding: 16px 14px;
    border-right: 1px solid #e1e6ef;
    color: #374767;
    font-size: 26px;
    line-height: 26px;
}

.logo {
    display: block;
    /*float: left;*/
    height: 80px;
    font-size: 60px;
    line-height: 60px;
    /*text-align: center;*/
    /*width: 250px;*/
    padding: 0 15px;
    font-weight: 300;
    /*color: #dcdcdc;*/
    -webkit-transition: width .3s ease-in-out;
    transition: width .3s ease-in-out
}

.main-header .logo,
.user-panel,
.wrapper {
    overflow: hidden
}

.main-sidebar .logo .logo-lg img {
    height: 100px;
    width: 100px;
}

.main-sidebar {
    position: fixed;
}

.content-header {
    /*position: relative;*/
    padding: 2px 10px 10px;
    background-color: #fff;
    border-bottom: 1px solid #e1e6ef;
    margin-bottom: 20px;
}

.active {
    color: #1290a4;
}


.header-icon {
    font-size: 50px;
    color: #389081;
    width: 68px;
    float: left;
    /*margin-top: -4px;*/
    line-height: 0;
}

.header-title {
    margin-left: 60px;
    margin-top: 5px;

}
.breadcrumb .breadcrumb-item{
    font-size: 14px;
}

.header-title .breadcrumb {
    float: right;
    background: #fff;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 12px;
    padding: 7px;
    /*position: absolute;*/
    bottom: 18px;
    right: 30px;
    border-radius: 0;
    border: 1px solid #e1e6ef;
    font-weight: 600;
}

.main-sidebar {
    position: absolute;
    top: 0;
    left: 0;
    /*padding-top: 60px;*/
    height: 100%;
    /*height: 100%;*/
    width: 250px;
    z-index: 810;
    /*overflow-y: scroll;*/
    overflow: hidden;
    -webkit-transition: -webkit-transform .3s ease-in-out,width .3s ease-in-out;
    -webkit-transition: width .3s ease-in-out,-webkit-transform .3s ease-in-out;
    transition: width .3s ease-in-out,-webkit-transform .3s ease-in-out;
    background-color: #2b3548;
    transform: translate(0,0);
    /*transform: translate3d(-100vw, 0, 0);*/
}
.responsive-sidebar {
    position: absolute;
    top: 0;
    left: 0;
    /*padding-top: 60px;*/
    height: 100%;
    /*height: 100%;*/
    width: 0px;
    z-index: 810;
    /*overflow-y: scroll;*/
    overflow: hidden;
    webkit-transition: -webkit-transform .5s ease-in-out, margin .5s ease-in-out;
    -webkit-transition: margin .5s ease-in-out, -webkit-transform .5s ease-in-out;
    transition: margin .5s ease-in-out, -webkit-transform .5s ease-in-out;
    transition: transform .5s ease-in-out, margin .5s ease-in-out;
    transition: transform .5s ease-in-out, margin .5s ease-in-out, -webkit-transform .5s ease-in-out;
    background-color: #2b3548;
    transform: translate(0,0);
    /*width: 50px!important;*/
    /*transform: translate3d(-100vw, 0, 0);*/
}
.sidebar {
    /*padding-bottom: 10px*/
}

.main-sidebar .user-panel {
    white-space: nowrap;
    overflow: hidden;
}

.user-panel {
    position: relative;
    width: 100%;
    padding: 20px 10px;
}

.user-panel .image img {
    width: 100%;
    max-width: 65px;
    height: auto;
    border: 2px solid rgba(255, 255, 255, .1);
    padding: 5px;
}

.image-circle {
    border-radius: 50%;
}

.user-panel .info p {
    font-weight: 500;
    color: #fff;
    margin: 5px 0;
    letter-spacing: 1px;
}

.sidebar-menu li {
    display: block;
    color: #dcdcdc;
    position: relative;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: .3px;
}
.sidebar-menu .tree-view-menu li{
    padding: 12px 5px 12px 15px;
}
.sidebar-menu .tree-view-menu{
    position: relative;
    /*display: none;*/
    list-style: none;
    padding: 5px 0 10px 35px;
    margin: 0;
    cursor:pointer;
    /*background-color: #404e67;*/
}

.sidebar-menu .tree-view-menu::before {
    width: 2px;
    bottom: 0;
    background: #389081;
    content: "";
    position: absolute;
    top: 0;
    left: 33px
}
.sidebar-menu .tree-view-menu li::before {
    left: 0;
    top: 18px;
    width: 15px;
    content: ' ';
    position: absolute;
    display: inline-block;
    border: 1px solid #389081;
}

/*.sidebar-menu .treeview-menu>li {*/
/*    margin: 0;*/
/*    position: relative*/
/*}*/
/*.sidebar-menu>li.active>span{*/
/*    color: #dcdcdc;*/
/*    background: #3bafa9;*/
/*}*/

.content {
    /*margin-left: 250px;*/
    /*min-height: 250px;*/
    /*margin-right: auto;*/
    /*margin-left: auto;*/
    /*padding: 0 30px 10px;*/
}

.card {
    background-color: white;
    word-wrap: normal;
}
.invoice-card{
    max-width: 900px;
    width: 90%;
    margin: 0 auto;
}

.card-action {
    border-top: 1px solid #cbcbcb;
}

.card-widget {
    /* background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1); */
    /*margin-bottom: 30px;*/
    padding: 15px 10px 15px;
    text-align: center;
    margin-bottom: 5px;
    /*position: relative;*/
    /*overflow: hidden;*/
}
.card-widget .info{
    margin-top: 10px;
    color: #32a552;
}

.pharmacy-icon {
    font-size: 13px;
    /*left: 0;*/
    /*top: 0;*/
    /*bottom: 0;*/
    /*width: 50px;*/
    /*height: 50px;*/
    /*border: 1px solid #d3d4d8;*/
    /*border-radius: 50%;*/
    /*text-align: center;*/
    /*line-height: 48px;*/
    /*color: gray;*/
    /*font-size: 27px;*/
    /*display: inline-block;*/

}
.pharmacy-img{
    width: 40px;
    height: 40px;
}
.pharmacy-image{
    height: 40px;
}

.sidebar-menu li > span > .fa-angle-left,
.sidebar-menu li > span > .pull-right-container > .fa-angle-left {
    /*width: auto;*/
    /*height: auto;*/
    padding: 0;
    margin-right: 10px
}

.m-r-15 {
    margin-right: 15px !important;
}
.navlink {
    display: block;
    color: #303030;
    /* font-size: 14px; */
    font-weight: inherit;
    text-decoration: none;
    /* text-shadow: 3px 3px 3px #80c8cf; */
}

.navlink:hover {
    text-decoration: none;
    color: #1290a4;

    /* transition: .5s ease;
    transform: scale(1.03); */
    font-weight: 500;
}
.dashboard-link {
    display: inline;
    color: #dcdcdc;
    /* font-size: 14px; */
    font-weight: inherit;
    text-decoration: none;
    font-size: 14px;
    /* text-shadow: 3px 3px 3px #80c8cf; */
}

.dashboard-link:hover {
    text-decoration: none;
    color: #19976d;

    /* transition: .5s ease;
    transform: scale(1.03); */
    font-weight: 500;
}

.active {
    text-decoration: none;
    /*color: #389081;*/
    color: #19976d;
    /* text-shadow: 2px 2px 4px #676767; */
    font-weight: 500;
}


.right-side {
    -webkit-transition: -webkit-transform .3s ease-in-out, margin .3s ease-in-out;
    -webkit-transition: margin .3s ease-in-out, -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out, margin .3s ease-in-out, -webkit-transform .3s ease-in-out;
    margin-left: 250px;
    z-index: 820
}

.main-footer {
    position: relative;
    clear: both;
    background: #fff;
    color: #444;
    border-top: 1px solid #d2d6de;
    margin-left: 250px;
    padding: 20px 0;
}
.responsive-footer {
    /*position: fixed;*/
    background: #fff;
    padding: 15px;
    color: #444;
    border-top: 1px solid #d2d6de;
    margin-left: 0;
    /*bottom: 0;*/
    /*width: 100%;*/
}

.pharmacy-btn {
    background-color: #389081;
    color: whitesmoke;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.pharmacy-btn:hover {
    border: 1px solid #389081;
    background-color: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    color: #389081;
}
.pharmacy-gray-btn{
    background-color: #DCDCDC;
    color: #505050;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.pharmacy-gray-btn:hover {
    border: 1px solid #DCDCDC;
    background-color: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    color: #505050;
}
.grey-btn{
    border: 1px solid #DCDCDC;
    background-color: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    color: #313131;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-left: 0;
}




.pharmacy-info-btn {
    background-color: #1fa7b0;
    color: whitesmoke;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.pharmacy-info-btn:hover {
    border: 1px solid #1fa7b0;
    background-color: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    color: #1fa7b0;
}

.pharmacy-primary-btn {
    background-color: #036a96;
    color: whitesmoke;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.pharmacy-primary-btn:hover {
    border: 1px solid #043a57;
    background-color: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    color: #045479;
}

.pharmacy-amber-btn {
    background-color: #f0ad4e;
    color: whitesmoke;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.pharmacy-amber-btn:hover {
    border: 1px solid #f0ad4e;
    background-color: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    color: #cea12a;
}

.pharmacy-btn-dismiss {
    background-color: #c4495c;
    color: whitesmoke;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.pharmacy-btn-dismiss:hover {
    border: 1px solid #c4495c;
    background-color: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    color: #c4495c;
}

.pharmacy-btn-outline {
    background-color: #fff;
    color: #389081;
    border: 1px solid #389081;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.pharmacy-btn-outline:hover {
    background-color: #389081;
    color: whitesmoke;
}

.pharmacyLogo {
    cursor: pointer;
    text-decoration: none;
    font-size: 1.1em;
    margin-left: 5%;
    color: #702acf;
    text-shadow: 1px 4px 3px rgba(174, 174, 174, 0.3);
    font-weight: 600;
}

.pharmacyLogo img {
    width: 130px;
    margin-left: 10px;
}

.pharmacyLogo:hover {
    color: #32a552;
}

.slide-enter {
    max-height: 0px;
    -webkit-transition: max-height 1s ease;
    overflow: hidden;
}

.slide-enter.slide-enter-active {
    height: auto;
    max-height: 100px;
}

.slide-leave {
    max-height: 100px;
    -webkit-transition: max-height 1s ease;
}

.slide-leave.slide-leave-active {
    overflow: hidden;
    max-height: 0px;
}
/*.drop-down{*/
/*    display: none;*/
/*}*/
.custom-page ul {
    list-style: none;
}
.custom-page ul li {
    border: 1px solid silver;
    cursor: pointer;
    display: inline;
    padding:10px;
}
.asterics{
    font-size: 1em;
    font-weight: bold;
    color: #c4495c;
}
.grey_btn {
    background-color: red;
    border: 2px solid black;
}
/*.card-item {*/
/*    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);*/
/*}*/
.dashboard-container-content{
    /*max-width: 90%;*/
    /*width: 90%;*/
    margin: 0 30px;

}
.row.dashboard-progress-report{
    justify-content: flex-end;
}
.progress-report-chart{
    margin-top: 30px;
}
.dashboard-cards{
    margin-bottom: 30px;
}
.chart-header{
    border-bottom: 1px solid #7a7878;
}
 .responsive-dashboard-text {
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 200;
}
.card-item .res-dashboard-text{
    font-weight: bold;
}
.user-edit-card{
    margin: 5% 20%;

}
.user-edit-card .edit-header {
    /*background: #21b1bb;*/
    background: url("./images/header.jpg");
    background-size: cover;
    height: 200px;
}
.user-edit-card .edit-image {
    position: relative;
    top: -60px;
    margin-bottom: -60px;
    margin-left: 3%;
}
.user-edit-card .icon-button{
    position: relative;
    /*top: -35px;*/
    /*margin-bottom: -30px;*/
    margin-left: 11%;

}
.user-edit-card .icon-button .icon-holder{
    position: relative;
    background: rgba(51, 51, 51, 0.61);
    color: white;
    border-radius: 50%;
    top: -35px;
    margin-bottom: -15px;
    /*margin-left: 15%;*/
    /*width: 80%;*/
    /*height: 50px;*/
}
.user-edit-card .edit-image img{
    width: 110px;
    height: 110px;
    max-width: 120px;
    max-height: 120px;
    border-radius: 50%;
    border: 3px solid rgba(255, 255, 255, 0.5);

}
.user-edit-card .edit-data{
    position: relative;
    margin: 5% 3% ;
    /*margin-left: 3%;*/
}
.styled-card{
    max-width: 600px;
    width: 90%;
    margin: 0 auto;
    /*box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);*/
}
.styled-invoice-card{
    max-width:800px;
    margin: 2% auto;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}
.pos-invoice-style{
    margin: 0 auto;
    max-width: 400px;
}
.styled-pos-card{
    width:95mm;
    /*box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);*/
    /*width:200px;*/
    padding:0 7px;
}
.styled-pos-card td{
    color: #000;
}
.styled-table-card{
    margin: 6% 8%;
    /*box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);*/
}
.styled-person-table{
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}
.styled-manage-person{
    max-width: 800px;
    width: 90%;
    margin: 25px auto;
}
.underline-text{
   text-decoration: underline;
    font-weight: bold ;
}
.heading-text{
    font-weight: bold ;
}
.styled-expense{
    margin: 5% 2%;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}
.styled-person-card{
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}
.styled-component{
    max-width: 800px;
    margin:0 auto;
    width: 95%;
}
.styled-unit-card{
    max-width: 600px;
    margin:0 auto;
    width: 95%;
}
.styled-edit-medicine{
    max-width: 900px;
    margin:0 auto;
    width: 95%;
    width: 90%;
    max-width: 900px;
    margin: 15px auto;
}

.styled-return-card{
    width: 90%;
    max-width: 800px;
    margin: 25px auto;
}
.styled-support-card{
    /*center a div vertically and horizontally*/
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mini-styled-component{

    width: 90%;
    max-width: 600px;
    margin: 85px auto;
}
.add-medicine-card{
    margin: 0 15px;
}
.styled-buttons{
    margin-bottom: 1%;
}
.styled-person-table{
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}

.styled-report-card{
    margin: 3% 8%;
    /*box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);*/
}
.style-employee-details{
    margin: 4% 7%;
}
.styled-employee-card .employee-header{
    background: url("./images/header.jpg");
    background-size: cover;
    height: 200px;
}
.styled-employee-card .employee-image {
    position: relative;
    top: -150px;
    margin-bottom: -80px;
    left: 35%;
}
.styled-employee-card .employee-image img{
    width: 110px;
    height: 110px;
    max-width: 120px;
    max-height: 120px;
    border-radius: 50%;
    border: 3px solid rgba(255, 255, 255, 0.5);

}
.styled-login-card{
    /*width:400px;*/
    /*margin: 10% auto;*/
    box-shadow: 5px 5px 10px 3px rgba(0, 0, 0, 0.1);

}

.styled-login{
    width:700px;
    margin: 5% auto;
}

.styled-reset-card{
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}

.styled-reset{
    width:400px;
    margin: 10% auto;
}
.styled-image img{
    width: 120px;
    height: 120px;
}
.styled-reset-card .styled-reset-content h4{
    color: #389081;
}
.styled-login-card .styled-content h4{
    color: #389081;
}
.sidebar-menu .tree-view{
    cursor:pointer;
    padding: 12px 25px 12px 15px;
}
.sidebar-menu .tree-view:hover{
    background:#389081;
    color: white;
}
/*.sidebar-menu .tree-view:active{*/
/*    background:#389081;*/
/*    color: white;*/
/*}*/
.hidden-list{
    display: none;
}
.password-icon{
    position:absolute;
    right: 5px;
    top:8px
}
.user-support{
    width: 800px;
    margin: 3% auto;
}
.user-support .card{
    padding: 20px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    border: 0;
}
.user-support .support-icon{
    font-size: 25px;
}
.user-support .team-text{
    font-weight: 400;
}
.twitter-support-icon{
    font-size: 50px;
    color:#38A1F3;
}
.responsive-div {
    width:800px;
    margin-left: auto;
    margin-right: auto;
}
.card-login {
    box-shadow: 5px 5px 10px 3px rgba(0, 0, 0, 0.1);
}
.responsive-div-right {
    padding-left: 30px;
    margin-bottom: 10px;
    margin-top: 50px;
    color: white;
}
.login-text{
    color: #338375;
    margin-bottom: 10px;
}

.fa {
    padding: 5px 10px;
    font-size: 15px;
    width: 15px;
    text-align: center;
    text-decoration: none;
    margin: 5px 5px;
    border-radius:50px;
}

.fa:hover {
    opacity: 0.7;
}

.fa-facebook {
    background: #3B5998;
    color: white;
}

.fa-twitter {
    background: #55ACEE;
    color: white;
}
.fa-whatsapp{
    background:#4FCE5D;
    color:white;
}


.loading-gif{
    width: 80px;    
    margin-left: 390%; 
}
.todays-report-gif{
    width:80px;
    margin-left: 200%;
}

.styled-expired-card{
    max-width:800px;
    margin: 5% auto;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    padding-bottom: 50px;
}
.expired-heading{
    background-color: #c4495c;
    color: white;
    text-align: center;
    font-size: 18px;
}

.styled-change-password{
    max-width:800px;
    margin: 5% auto;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}
.styled-reset-page{
    max-width:800px;
    margin: 5% auto;
}

.invalid-text{
    color:#c4495c;
    font-size: 13px;
}
.disabled{
    cursor:not-allowed;
}
.text-wrap{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

}
.mpeke-loader{
    margin-left: 350%;
}
.sales-loader{
    margin-left: 200%;
}
.sales-loader img{
    width: 80px;
}
.mpeke-loader  img{
    width: 80px;
}
.MuiCard-root{
    overflow: visible !important;
}
.report-search-form{
    display: flex;
    /*background: #fff;*/
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    flex-wrap: wrap;
    /*width: 1000px;*/
}
.report-range{
    flex: 1 1 30%;
}
.report-form{
    flex: 1 1 70%;
}
.form-control-group{
    display: flex !important;
    flex: 1 1 40% !important;
    margin-right: 5px !important;
}
.form-control-button{
    display: flex !important;
    flex: 1 1 10% !important;
}
.form-text-box{
    width: 70% !important;
}
.form-select-box{
    width: 50% !important;
}
.purchase-order-root{
    max-width: 210mm;
    width: 90%;
    margin: 0 auto;
}
.purchase-order{
    width: 210mm;
    /*min-height: 297mm;*/
    background-color: #fff;
    /*border: 1px solid #DCDCDC;*/
    /*box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);*/
    padding: 30px ;
}
.address-section, .vendor-section{
    display: flex;
    flex-wrap: wrap;
}
.order_company_address{
    flex: 2 1 60%;
}
.order_company_title{
    flex: 1 1 40%;
    text-align: right;
}
.purchase-order p {
    margin-bottom: .1rem !important;
}
.vendor-address{
    flex: 1 1 50%;
    margin-right: 10%;
}
.shipping-address{
    flex: 1 1 40%;
}
.purchase-order-title{
    color: #1290a4;
    font-size: 1.5rem;
}
.vendor-title, .shipping-title{
    background-color: #1290a4;
    padding: 4px 8px;
    color: #fff;
}
.vendor-title{
    width: 80%;
}
.vendor-table thead{
    background-color: #1290a4;
    color: #fff;
    text-transform: uppercase;
}
.item-table > .table-bordered{
    border-bottom: 2px solid #a9aaaa;
}
.vendor-table th{
    border: 0;
    text-align: center;
    font-weight: normal;
}
.vendor-row td{
    text-align: center;
}
.vendor-table thead th{
    border-bottom: 0;
}
.vendor-table thead th:first-child{
    border-left: 1px solid #a9aaaa;
}
.vendor-table thead th:last-child{
    border-right: 1px solid #a9aaaa;
}
.vendor-table td{
    padding: .7rem;
    /*border-left: 2px solid #a9aaaa;*/
}
.vendor-table td:last-child{
    /*border-right: 2px solid #a9aaaa;*/
}

.vendor-table.product-table td{
    /*padding: .2rem;*/
}
.purchase-comment{
    /*text-align: center;*/
    /*margin-top: 4px;*/
    text-decoration: underline;
}

.styled-stock-card{
    padding: 8px;
    border: 1px solid #dcdcdc;
}
.styled-stock-form{
    display:flex;
}
.stock-sorting, .stock-range, .sort-btn{
    flex: 1 0 20%;
}
.stock-date{
    flex:2 0 40%;
}
.report-search-form{
    display: flex;
    background: #fff;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    flex-wrap: wrap;
    /*width: 1000px;*/
}
.customer-purchase-card{
    margin: 0 auto;
    max-width: 1100px;
    width: 98%;
}
.report-range{
    flex: 1 1 30%;
}
.report-form{
    flex: 1 1 70%;
}
.form-control-group{
    display: flex !important;
    flex: 1 1 40% !important;
    margin-right: 5px !important;
}
.form-control-button{
    display: flex !important;
    flex: 1 1 10% !important;
}
.form-text-box{
    width: 70% !important;
}
.form-select-box{
    width: 50% !important;
}
.disabled-table{
    color: rgb(170, 170, 170);
    background-color: rgb(226 223 223);
    border-color: rgba(118, 118, 118, 0.3);
    cursor:not-allowed;
}
.styled-stock-card{
    padding: 8px;
    border: 1px solid #dcdcdc;
}
.styled-stock-form{
    display:flex;
}
.stock-sorting, .stock-range, .sort-btn{
    flex: 1 0 20%;
}
.stock-date{
    flex:2 0 40%;
}
.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
}
.pagination > .active > a{
    background-color: #1290a4 ;
    border-color: #1290a4 ;
    color: #fff;
}
.pagination > li > a{
    border: 1px solid rgba(118, 118, 118, 0.3);
    padding:  10px;
    outline: none;
    cursor: pointer;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: #1290a4 ;
    border-color: #1290a4;
    outline: none ;
}
.pagination > li > a, .pagination > li > span{
    color: rgba(0, 0, 0, 0.66)
}
.pagination > li:first-child > a, .pagination > li:first-child > span{
    border-top-left-radius: 10%;
    border-bottom-left-radius: 10%;
}
.pagination > li:last-child > a, .pagination > li:last-child > span{
    border-top-right-radius: 10%;
    border-bottom-right-radius: 10%;
}
.page-item.active .page-link{
    background-color: #1290a4 ;
    border-color: #1290a4;
}
.page-item:hover, .page-link:hover{
    color:#1290a4 ;
}
.styled-manage-customer{
    max-width: 1200px;
    width: 80%;
    margin: 3% auto;
}
.drop-down, .product-drop-down{
    /*max-width:500px;*/
    overflow:auto;
    /*top: 80px;*/
    /*!*margin-top:-38px;*!*/
    /*left: 40px;*/
    width:200px;
    height:50px;
    z-index:100;
    padding: 0;
    position: absolute;
}

.product-search{
    /*max-width: 900px;*/
    margin: 20px auto;
    width: 90%;
    display: flex;
    justify-content: space-between;
}
.search-product{
    width: 30%;
}

.category{
    width: 30%;
}
.chart-container{
    max-width: 1000px;
    width: 100%;
    height: 70vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.sort-by{
    /*margin: 20px 0px;*/
}

@media screen and (max-width: 625px){
    .order-company-title{
        text-align: left;
    }
}

@media (min-width: 767px) and (max-width:821px) {
    footer .row {
        margin-left: 250px;
        padding: 0 10px;
        
    }
}
@media (max-width:450px){
    .footer-text{
        text-align: center !important;
        /* padding-left: 10px; */
    }
}

@media print {
    @page {
        /*size: 80mm 297mm;*/
        /*margin: 5mm 5mm 5mm 5mm; !* change the margins as you want them to be. *!*/
    }
}



